@use "assets/styles/colors";
@use "assets/styles/breakpoints";
@use "assets/styles/base";

.container {
  width: 100%;
  height: 80px;
  padding-top: 12px;
  @include base.row(space-between);
  @include base.padding;

  @media screen and (min-width: breakpoints.$media_l) {
    padding-top: 48px;
  }
}

.logo {
  width: 60px;
  color: colors.$text_light_primary;
  cursor: pointer;
}

.logoDark {
  color: colors.$text_dark_primary;
}

.itemsContainer {
  position: relative;
  @include base.row(flex-start, 24px);

  @media screen and (min-width: breakpoints.$media_l) {
    @include base.row(flex-start, 48px);
  }
}

.items {
  display: none;

  @media screen and (min-width: breakpoints.$media_m) {
    @include base.row(flex-start, 24px);
  }

  @media screen and (min-width: breakpoints.$media_l) {
    @include base.row(flex-start, 48px);
  }
}

.menuItem {
  color: colors.$white;
  font-weight: 700;
  font-size: 14px;
  transition: 0.2s ease;
  background-color: transparent;
  border: none;
  font-family: "Sk-Modernist", sans-serif;
  cursor: pointer;

  &:hover {
    color: colors.$light_secondary;
    transform: translateY(-3px);
  }

  @media screen and (min-width: breakpoints.$media_m) {
    font-size: 16px;
  }
}

.menuItemDark {
  color: colors.$text_dark_primary;
}

.menuButtonItem {
  @extend .menuItem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
}

.activeMenuItem {
  color: colors.$light_secondary;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: colors.$light_secondary;
    }
  }
}

.button {
  @extend .button;
  display: none;

  @media screen and (min-width: breakpoints.$media_m) {
    display: block;
  }
}
