@use "assets/styles/colors";
@use "assets/styles/breakpoints";

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  padding: 64px 0;

  @media screen and (min-width: breakpoints.$media_l) {
    margin-left: -40px;
  }
}

.title {
  font-family: "Marcellus", sans-serif;
  font-weight: 400;
  font-size: 42px;
  margin: 0;
  color: colors.$text_dark_primary;
  white-space: break-spaces;

  span {
    color: colors.$green_primary;
  }
}

.detail {
  width: 24px;
  height: 42px;
  margin-top: 6px;

  //path {
  //  fill: colors.$green_primary;
  //}
}

.smallDetail {
  width: 24px;
  height: 20px;
  margin-top: 10px;
}

.small {
  font-size: 32px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
