@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/variables";
@use "assets/styles/breakpoints";
@use "assets/styles/typography";

.container {
  width: 100%;
  @include base.padding;
  padding-right: 0 !important;
  overflow-x: hidden;
  padding-bottom: 50px;
  position: relative;
  padding-top: 64px;

  @media screen and (min-width: breakpoints.$media_l) {
    min-height: auto;
    padding-top: 84px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: variables.$content_padding_media_l !important;
    gap: 48px;
  }

  @media screen and (min-width: breakpoints.$media_xl) {
    padding-right: variables.$content_padding_media_xl;
  }
}

.content {
  @include base.padding;
  padding-left: 0 !important;
}

.text {
  @extend .body1;
  white-space: pre-wrap;
  color: colors.$dark_secondary;
  line-height: 180%;
  text-align: center;
}

.details {
  width: 100%;
  height: 100%;
  @include base.padding;
  padding-left: 0 !important;
  position: relative;
}

.dotsBackground {
  position: absolute;
  top: 0;
  right: -90%;
  height: 100%;
  opacity: 0.4;

  @media screen and (min-width: breakpoints.$media_l) {
    top: 0;
    right: 0;
    opacity: 0.8;
  }
}

.icon {
  @media screen and (min-width: breakpoints.$media_l) {
    margin-top: 84px;
    transform: scale(1.4);
    transform-origin: right bottom;
  }
}
