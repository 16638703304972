@use "assets/styles/base";
@use "assets/styles/colors";

.button {
  @extend .button;
  height: 28px;
  font-size: 12px;
  padding: 12px 16px;

  svg {
    height: 24px;
    transform: rotate(-90deg);
  }
}

.icon {
  width: 42px;
  height: 42px;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
