@use "assets/styles/base";
@use "assets/styles/colors";

.container {
  @include base.padding;
  width: 100%;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
  gap: 24px;
  padding-bottom: 64px;
}

.noContentWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  svg {
    width: 22px;
    height: 22px;
    color: colors.$dark_secondary;
  }
}

.noContent {
  font-size: 16px;
  color: colors.$dark_secondary;
}
