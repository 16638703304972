@use "assets/styles/base";
@use "assets/styles/animations";

.container {
  @include base.padding;
  @include animations.fade-in;
  width: 100%;
  padding-right: 0 !important;
  margin-right: 0 !important;
  margin-top: 64px;
}

.content {
  display: flex;
  flex-direction: column;
}
