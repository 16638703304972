@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/breakpoints";
@use "assets/styles/animations";

.container {
  @include animations.fade-in;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.screen {
  position: absolute;
  top: 50%;
  right: -700px;
  transform: translateY(-50%);
  height: 60%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 38px;
  z-index: 12;
}

.video {
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

@keyframes move {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}

.background {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  animation: move 140s linear infinite;
  z-index: 0;
  object-fit: cover;

  @media screen and (min-width: breakpoints.$media_l) {
    z-index: 0;
  }
}

.planet {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%) scale(0.8);
  animation: spin 140s linear infinite;
  z-index: 0;

  @media screen and (min-width: breakpoints.$media_s) {
    transform: translate(-50%, -50%) scale(1);
  }

  @media screen and (min-width: breakpoints.$media_l) {
    transform: translate(-50%, -50%) scale(1.3);
    z-index: 0;
  }
}

.satellite {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);

  @media screen and (min-width: breakpoints.$media_l) {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.innerContent {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 120px;
  @include base.padding;

  @media screen and (min-width: 100px) {
    padding-bottom: 80px;
  }

  @media screen and (min-width: breakpoints.$media_l) {
    max-width: 1000px;
    justify-content: center;
    padding-bottom: 70px;
  }
}

.title {
  @include animations.fade-in-down(0.4s);
  font-size: 38px;
  line-height: 100%;
  font-family: "Sk-Modernist", sans-serif;
  font-weight: bold;
  color: colors.$white;
  margin-bottom: 30px;
  white-space: break-spaces;

  span {
    color: colors.$green_secondary;
  }

  @media screen and (min-width: breakpoints.$media_xl) {
    font-size: 48px;
    white-space: pre-wrap;
  }

  @media screen and (min-width: breakpoints.$media_xxl) {
    font-size: 52px;
  }
}

.description {
  @include animations.fade-in-down(0.4s, 0.1s);
  color: colors.$text_light_secondary;
  font-size: 18px;
  line-height: 180%;
  font-family: "Sk-Modernist", sans-serif;
  font-weight: 400;
}

.button {
  @extend .button;
  max-width: 240px;
  margin-top: 50px;
}
