@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/typography";

.container {
  @include base.padding;
  padding-bottom: 64px;
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.description {
  @extend .body1;
  color: colors.$dark_secondary;
  line-height: 200%;
  white-space: break-spaces;
}
