@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/typography";

.container {
  @include base.padding;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(260px, 100%), 1fr));
  gap: 48px;
  padding-bottom: 64px;
}

.itemContainer {
  svg {
    height: 80px;
  }

  h2 {
    font-size: 20px;
    font-family: Marcellus, sans-serif;
  }

  p {
    @extend .body1;
    line-height: 180%;
    color: colors.$dark_secondary;
  }
}
