@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/breakpoints";

.container {
  width: 100%;
  position: relative;
  min-height: 400px;
}

.content {
  @include base.padding;
  padding-top: 64px;
  padding-bottom: 64px;
  color: colors.$white;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 3;
  transform: translateY(-50%);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.title {
  color: colors.$text_light_primary;
  font-size: 32px;
  font-family: "Sk-Modernist", sans-serif;
  white-space: break-spaces;

  @media screen and (min-width: breakpoints.$media_m) {
    font-size: 42px;
  }

  span {
    color: colors.$light_secondary;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.logo {
  width: 60px;
  color: colors.$text_light_primary;
}

.phoneNumber {
  font-size: 32px;
  color: colors.$text_light_primary;
}
