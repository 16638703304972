@use "assets/styles/base";

.sectionContainer {
  @include base.padding;
  margin-bottom: 84px;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
