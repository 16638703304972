@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/typography";
@use "assets/styles/breakpoints";

.container {
  @include base.padding;
  width: 100%;
  background-color: colors.$white_primary;
  padding-bottom: 64px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 48px;

  @media screen and (min-width: breakpoints.$media_l) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
    justify-content: center;
  }
}

.icon {
  width: 56px;
  height: 56px;
}

.title {
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
  white-space: break-spaces;
  color: colors.$text_dark_primary;
}

.description {
  @extend .body2;
  color: colors.$dark_secondary;
  line-height: 160%;
}
