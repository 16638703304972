@use "assets/styles/colors";
@use "assets/styles/breakpoints";

.outer {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: none;
  background: none;
  width: 50px;
  height: 30px;
  padding: 1rem 0 1rem 1rem;
  box-sizing: content-box;
  cursor: pointer;
  z-index: 100;

  &:focus {
    outline: none;
  }

  @media screen and (min-width: breakpoints.$media_m) {
    display: none;
  }
}

.inner {
  position: relative;
  transition: background-color 0.15s ease;

  &::after,
  &::before {
    content: "";
    position: absolute;
    right: 0;
    height: 2px;
    background-color: #fff;
    transition: transform 0.25s ease, width 0.25s ease;
  }

  &::before {
    top: -3px;
  }

  &::after {
    width: 32px;
    top: 3px;
  }
}

.innerOpen {
  @extend .inner;

  &::after,
  &::before {
    background-color: colors.$green_primary;
  }

  &::before {
    width: 32px;
    top: -3px;
    transform: translateY(3px) rotate(42deg);
  }

  &::after {
    width: 32px;
    top: 3px;
    transform: translateY(-3px) rotate(-42deg);
  }
}

.darkInner {
  @extend .inner;

  &::after,
  &::before {
    background-color: colors.$green_primary;
  }
}

.innerClose {
  @extend .inner;

  &::before {
    width: 26px;
    top: -3px;
    transform: translateY(0) rotate(0);
  }

  &::after {
    width: 32px;
    top: 3px;
    transform: translateY(0) rotate(0);
  }
}
