@use "assets/styles/base";

.container {
  @include base.padding;
  margin-bottom: 128px;
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
  gap: 48px;
}

.image {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s ease;
  aspect-ratio: 3/2;
  object-fit: contain;

  &:hover {
    transform: translateY(-4px);
  }
}

.offerImage {
  all: unset;
}
