@use "./fonts.scss";
@use "./global.scss";
@use "./typography.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Sk-Modernist", sans-serif;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}
