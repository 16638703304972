@use "assets/styles/colors";
@use "assets/styles/animations";

.wrapper {
  width: 100%;
  @include animations.fade-in;
}

.innerWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.innerBackgroundWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #fff;
  background-position: top center;
  background-size: cover;
  background-repeat: repeat;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
}

@keyframes flash {
  0% {
    background-color: colors.$accent_gray;
    box-shadow: 12px 0 colors.$accent_gray, -12px 0 colors.$gray_dark;
  }
  50% {
    background-color: colors.$gray_dark;
    box-shadow: 12px 0 colors.$accent_gray, -12px 0 colors.$accent_gray;
  }
  100% {
    background-color: colors.$accent_gray;
    box-shadow: 12px 0 colors.$gray_dark, -12px 0 colors.$accent_gray;
  }
}

@keyframes flashLarge {
  0% {
    background-color: colors.$accent_gray;
    box-shadow: 32px 0 colors.$accent_gray, -32px 0 colors.$gray_dark;
  }
  50% {
    background-color: colors.$gray_dark;
    box-shadow: 32px 0 colors.$accent_gray, -32px 0 colors.$accent_gray;
  }
  100% {
    background-color: colors.$accent_gray;
    box-shadow: 32px 0 colors.$gray_dark, -32px 0 colors.$accent_gray;
  }
}

.pulse {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: colors.$accent_gray;
  box-shadow: 12px 0 colors.$accent_gray, -12px 0 colors.$accent_gray;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
  margin: 0 12px;
}

.pulseLarge {
  @extend .pulse;
  width: 16px;
  height: 16px;
  animation: flashLarge 0.5s ease-out infinite alternate;
}

.logo {
  width: 80%;
  filter: invert(1);
}

.text {
  font-size: 18px;
  font-weight: 500;
  color: colors.$text_dark_primary;
  margin-top: -24px;
}
