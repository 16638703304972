@use "assets/styles/base";

.container {
  @include base.padding;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
  gap: 24px;
  padding-bottom: 64px;
}
