@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/typography";
@use "assets/styles/breakpoints";
@use "assets/styles/animations";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  @media screen and (min-width: breakpoints.$media_l) {
    margin-top: 0;
  }
}

.title {
  @include animations.fade-in-down(0.4s);
  font-size: 48px;
  color: colors.$primary_main;
  margin-top: 0;
  margin-bottom: 24px;
}

.description {
  @include animations.fade-in-down(0.4s, 0.1s);
  @extend .body2;
  color: colors.$dark_secondary;
  line-height: 180%;
}

.button {
  @include animations.fade-in-down(0.4s, 0.2s);
  @extend .button;
  height: 38px;
  font-size: 14px;
  padding: 6px 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
