$black: #000;
$light_black: #252a35;

$background_dark: #2b2e34;
$accent: #da9225;
$accent_dark: #9e701c;

$gray: #f3f4f6;
$gray_dark: #e9e9e9;

$blue: #005eb8;
$dark_blue: #0072ce;

$green: #4c9a4e;

$background: #d9d9d9;
$background_primary: #0f161b;
$background_gray: #faf9f8;

// ----
$white: #fff;

$text_light_primary: #f5f5f5;
$text_light_secondary: #fbf9f7;

$text_dark_primary: #2d2d2d;
$text_dark_secondary: #101010;

$primary_main: #323139;

$dark_primary: #45444f;
$dark_secondary: #585664;
$dark_tertiary: #737082;
$dark_quaternary: #8d8b9f;

$light_primary: #c3aca0;
$light_secondary: #bfb8b9;
$light_tertiary: #bbc4c9;

$white_primary: #f0f0f0;
$white_secondary: #e9e9e9;
$white_tertiary: #d9d9d9;
$accent_gray: #0000000d;

$gray_primary: #98a0a8;
$gray_secondary: #d1d7dd;
$loading_accent_gray: rgba(0, 0, 0, 0.02);

$green_primary: #3e6c6b;
$green_secondary: #559391;
