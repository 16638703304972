@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/typography";
@use "assets/styles/breakpoints";
@use "assets/styles/animations";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 64px;

  @media screen and (min-width: breakpoints.$media_l) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.backWrapper {
  background-color: transparent;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  display: none;
  border: none;
  font-size: 14px;
  color: colors.$dark_tertiary;
  cursor: pointer;
  transition: 0.3s ease;
  margin-top: 24px;

  &:hover {
    svg {
      transform: translateX(-6px);
    }
  }

  svg {
    transition: 0.2s ease;
    width: 22px;
    height: 22px;
  }

  @media screen and (min-width: breakpoints.$media_l) {
    display: flex;
  }

  @media screen and (min-width: breakpoints.$media_xl) {
    margin-top: 0;
  }
}

.title {
  @include animations.fade-in-down(0.4s);
  font-size: 48px;
  color: colors.$primary_main;
  margin-top: 0;
  margin-bottom: 0;
}

.description {
  @extend .body2;
  @include animations.fade-in-down(0.3s, 0.1s);
  color: colors.$dark_secondary;
  line-height: 180%;
}

.button {
  @extend .button;
  height: 38px;
  font-size: 14px;
  padding: 6px 18px;
}

.attributesContainer {
  @include base.row("flex-start", 24px);
}

.attribute {
  @include animations.fade-in-down(0.3s, 0.2s);
  @include base.row;
  @extend .caption;
  color: colors.$text_dark_primary;
}

.attributeIcon {
  color: colors.$dark_quaternary;
  width: 20px;
  height: 20px;
}
