@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/typography";
@use "assets/styles/breakpoints";

.container {
  @include base.padding;
  width: 100%;
  background-color: colors.$white_primary;
  padding-bottom: 64px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 48px;

  @media screen and (min-width: breakpoints.$media_l) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
    justify-content: center;
  }
}

.icon {
  width: 48px;
  height: 48px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  white-space: break-spaces;
  color: colors.$text_dark_primary;
}

.itemContainer {
  h1 {
    font-size: 78px;
    font-weight: bold;
    margin: 0;
    color: colors.$text_dark_primary;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    white-space: break-spaces;
    color: colors.$text_dark_primary;
    margin-top: 0;
    margin-bottom: 24px;
  }

  p {
    @extend .body1;
    color: colors.$dark_secondary;
    line-height: 180%;
  }
}
