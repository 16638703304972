@use "assets/styles/colors";
@use "assets/styles/animations";
@use "assets/styles/breakpoints";

.menu {
  @include animations.fade-in-down;
  background-color: colors.$white;
  border-radius: 10px;
  border: 1px solid colors.$gray;
  padding: 0;
  z-index: 10000;
  -webkit-box-shadow: 5px 4px 30px -14px rgb(40, 38, 38, 0.6);
  -moz-box-shadow: 5px 4px 30px -14px rgb(40, 38, 38, 0.6);
  box-shadow: 5px 4px 30px -14px rgb(40, 38, 38, 0.6);
}

.option {
  all: unset;
  display: flex;
  height: 50px;
  flex-direction: row;
  gap: 15px;
  box-sizing: border-box;
  padding: 6px 18px;
  align-items: center;
  width: 100%;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:hover {
    background-color: colors.$gray_dark;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  svg {
    width: 24px;
    color: colors.$gray;
  }
}

.optionContainer {
  @extend .option;
  color: colors.$text_dark_secondary !important;
}

.deleteContainer {
  @extend .option;
}

.optionText {
  color: inherit;
  font-weight: 500;
  margin: 0;
  font-size: 13px;
}
