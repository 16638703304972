@use "assets/styles/base";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  h4 {
    margin-top: 0;
    margin-bottom: 12px;
  }

  p {
    font-weight: 400;
    margin: 6px 0;
  }

  button {
    white-space: nowrap;
    @extend .button;
    height: 42px;
    max-width: 240px;
    margin-top: 50px;
  }
}

.big {
  padding: 40px 0;

  button {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 280px;
  }
}

.medium {
  padding: 20px 0;

  button {
    margin-top: 15px;
    width: 150px;
  }
}

.small {
  padding: 10px 0;

  button {
    margin-top: 15px;
    width: 100px;
  }
}
