@use "assets/styles/animations";

.container {
  @include animations.fade-in-down(0.4s);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.button {
  width: 60px;
  height: 60px;
  background-color: transparent;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  svg {
    width: 48px;
    height: 48px;
  }
}
