@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/breakpoints";

.container {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  border-top: 1px solid colors.$gray_dark;
  border-bottom: 1px solid colors.$gray_dark;
  margin: 48px 0 84px 0;
  grid-gap: 1px;
  background-color: colors.$gray_dark;

  @media screen and (min-width: breakpoints.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.box {
  @include base.padding;
  background-color: colors.$gray;
  padding: 48px 24px;

  p {
    font-size: 16px;
    line-height: 32px;
  }
}
