@use "assets/styles/breakpoints";
@use "assets/styles/animations";

.container {
  @include animations.fade-in(0.2s);
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  place-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: none;

  @media screen and (min-width: breakpoints.$media_m) {
    display: grid;
  }
}

.close {
  background-color: transparent;
  border: none;
  padding: 24px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 24px;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
  }
}

.imageBox {
  width: 80%;
  height: 80%;
  padding: 24px;
  position: relative;
  display: grid;
  place-items: center;
}

.image {
  height: 80%;
  object-fit: contain;
  border-radius: 12px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  width: 60px;
  height: 60px;
  background-color: transparent;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  svg {
    width: 48px;
    height: 48px;
    //stroke: red;

    path {
      stroke: #fff;

      &:nth-child(2) {
        fill: #fff;
      }
    }
  }
}

.left {
  position: absolute;
  top: 50%;
  left: 60px;
}

.right {
  position: absolute;
  top: 50%;
  right: 60px;
}
