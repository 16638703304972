.body1 {
  font-size: 16px;
  margin: 4px 0;
}

.body2 {
  font-size: 14px;
  margin: 4px 0;
}

.subtitle1 {
  font-size: 20px;
  font-weight: 500;
  margin: 8px 0;
}

.subtitle2 {
  font-size: 18px;
  font-weight: 500;
  margin: 6px 0;
}

.caption {
  font-size: 12px;
  margin: 0;
}
