@use "assets/styles/colors";
@use "assets/styles/typography";
@use "assets/styles/breakpoints";

.container {
  margin-top: 24px;
  margin-bottom: 48px;

  @media screen and (min-width: breakpoints.$media_l) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  font-family: Marcellus, sans-serif;
  font-size: 26px;
  margin-top: 24px;
  margin-bottom: 24px;

  @media screen and (min-width: breakpoints.$media_xl) {
    margin-top: 0;
  }
}

.description {
  @extend .body2;
  line-height: 160%;
  color: colors.$dark_tertiary;
}
