@use "assets/styles/colors";
@use "assets/styles/breakpoints";

.trailsText {
  position: relative;
  width: 100%;
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
  font-size: 48px;
  line-height: 100%;
  font-family: "Sk-Modernist", sans-serif;
  font-weight: bold;
  color: colors.$white;

  span {
    color: colors.$green_secondary;
  }

  @media screen and (min-width: breakpoints.$media_xl) {
    font-size: 48px;
    //white-space: pre-wrap;
  }

  @media screen and (min-width: breakpoints.$media_xxl) {
    font-size: 52px;
  }

  div {
    padding-right: 0.05em;
    overflow: hidden;
  }
}

.trailsTextDark {
  @extend .trailsText;
  color: colors.$text_dark_primary;
}
