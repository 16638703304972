@use "assets/styles/colors";

.container {
  height: 75vh;
  width: 100%;
  display: grid;
  place-items: center;
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainTitle {
  font-size: 120px;
  color: colors.$dark_quaternary;
  opacity: 0.4;
  margin: 0;
}
