@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/typography";

.container {
  @include base.padding;
}

.description {
  @extend .body2;
  color: colors.$dark_secondary;
  text-align: center;
  line-height: 200%;
}

.subtitle {
  @extend .subtitle2;
  text-align: center;
  font-weight: 700;
  margin-top: 24px;
}

.status {
  text-align: center;
  margin: 0;
  margin-top: 12px;
  color: colors.$dark_secondary;
  font-size: 14px;
}

.sellDate {
  text-align: center;
  margin: 0;
  color: colors.$light_black;
}
