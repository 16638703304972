@use "assets/styles/base";

.container {
  width: 100%;
  @include base.padding;
  padding-right: 0 !important;
  margin-right: 0 !important;
}

.content {
  display: flex;
  flex-direction: column;
}

.errorContainer {
  align-items: flex-start;
  margin-bottom: 24px;
}
