@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/typography";
@use "assets/styles/animations";
@use "assets/styles/breakpoints";

.container {
  width: 100%;
  //display: flex;
  //flex-direction: column;
  min-height: 370px;
  border-radius: 10px;
  display: grid;
  padding-bottom: 24px;
  grid-template-rows: 40% 60%;
  border: 1px solid colors.$text_light_primary;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 12px;
  -moz-box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 12px;

  @media screen and (min-width: breakpoints.$media_s) {
    min-height: 380px;
  }

  @media screen and (min-width: breakpoints.$media_m) {
    min-height: 440px;
  }
}

.image {
  width: 100%;
  height: 100%;
  //height: 180px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.imageLoader {
  width: 100%;
  height: 100%;
  //height: 200px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: colors.$loading_accent_gray;
}

.content {
  padding: 16px;
  height: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  @include animations.fade-in;
  font-family: "Marcellus", sans-serif;
  margin-top: 0;
  font-size: 20px;
  white-space: break-spaces;
  color: colors.$text_dark_secondary;
}

.tooltip {
  background-color: #fff;
  border-radius: 6px;
  color: colors.$text_dark_primary;
  border: 1px solid colors.$text_light_primary;
  -webkit-box-shadow: rgba(149, 157, 165, 0.1) 0px 4px 12px;
  -moz-box-shadow: rgba(149, 157, 165, 0.1) 0px 4px 12px;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 4px 12px;
}

.soldText {
  font-size: 14px;
  color: colors.$dark_tertiary;
  margin-top: 0;
  margin-bottom: 12px;
}

.attributesContainer {
  @include base.row("flex-start", 24px);
}

.attribute {
  @include base.row;
  @extend .caption;
  color: colors.$dark_quaternary;
  gap: 4px;
}

.attributeIcon {
  color: colors.$dark_quaternary;
  width: 20px;
  height: 20px;
}

.price {
  @include animations.fade-in;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
}

.description {
  @include animations.fade-in;
  @extend .body2;
  color: colors.$dark_quaternary;
  margin-bottom: 16px;
  line-height: 160%;
}

.button {
  @extend .button;
  height: 32px;
  font-size: 14px;
  padding: 6px 12px;
}

.arrow {
  width: 18px;
  transform: rotate(-90deg);
}
