@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Sk-Modernist";
  src: url("../fonts/Sk-Modernist-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: "Sk-Modernist";
  src: url("../fonts/Sk-Modernist-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}
