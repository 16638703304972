@use "assets/styles/base";
@use "assets/styles/colors";
@use "assets/styles/variables";
@use "assets/styles/breakpoints";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: breakpoints.$media_xl) {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 40% 60%;
    padding-bottom: 48px;
  }
}

.slider {
  margin-bottom: 24px;

  @media screen and (min-width: breakpoints.$media_l) {
    grid-column: 2;
    margin-bottom: 0;
  }
}

.mobileController {
  display: flex !important;

  @media screen and (min-width: breakpoints.$media_l) {
    display: none !important;
  }
}

.desktopController {
  display: none !important;

  @media screen and (min-width: breakpoints.$media_l) {
    display: flex !important;
    padding-bottom: 84px;
    padding-right: variables.$content_padding_media_l;
  }

  @media screen and (min-width: breakpoints.$media_xl) {
    padding-right: variables.$content_padding_media_xl;
  }
}

.image {
  width: 100%;
  height: 400px;
  object-fit: cover;

  @media screen and (min-width: breakpoints.$media_l) {
    height: 500px;
  }
}

.box {
  width: 100%;
}

.logo {
  transform: scale(0.6);
  transform-origin: left bottom;
  margin-top: -20px;
  margin-bottom: 24px;

  path {
    fill: colors.$green_primary;
  }

  @media screen and (min-width: breakpoints.$media_l) {
    margin-bottom: 64px;
  }
}

.text {
  color: colors.$text_dark_secondary;
  font-size: 16px;
  line-height: 180%;
}

.content {
  @include base.padding;
  padding-left: 0 !important;

  @media screen and (min-width: breakpoints.$media_l) {
    align-self: center;
  }
}
