@use "assets/styles/colors";
@use "assets/styles/typography";
@use "assets/styles/breakpoints";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: breakpoints.$media_l) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 64px;
  }
}

.content {
}

.image {
  border-radius: 10px;
  width: 100%;
  border: 1px solid #0000000d;

  @media screen and (min-width: breakpoints.$media_l) {
    width: 50%;
  }
}

.containerReverse {
  @media screen and (min-width: breakpoints.$media_l) {
    flex-direction: row-reverse;
  }
}

.title {
  font-size: 32px;
}

.description {
  @extend .body2;
  white-space: break-spaces;
  color: colors.$dark_secondary;
  line-height: 160%;
}
